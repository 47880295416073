<template>
    <div>
        <div class="demo-spin-article">
            <div class="mb-3">
                <label class="col-label form-label required tw-text-xs">{{
                    $t('itemAllowance.allowanceItem')
                }}</label>
                <div class="col-md-12">
                    <input
                        :class="{
                            'is-invalid': errors.has('allowance_item')
                        }"
                        v-model="model.allowance_item"
                        type="text"
                        class="form-control from-label"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('allowance_item')"
                    >
                        {{ errors.first('allowance_item') }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label class="col-label form-label required tw-text-xs">{{
                    $t('itemAllowance.fringeBenefitPercent')
                }}</label>
                <div class="col-md-12">
                    <div>
                        <InputNumber
                            :max="100"
                            v-model="model.fringe_benefit_percent"
                            :formatter="value => `${value}%`"
                            :parser="value => value.replace('%', '')"
                            :class="{
                                'is-invalid': errors.has(
                                    'fringe_benefit_percent'
                                )
                            }"
                            :min="0"
                            style="width: 487px"
                        ></InputNumber>
                    </div>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('fringe_benefit_percent')"
                    >
                        {{ errors.first('fringe_benefit_percent') }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label class="col-label form-label required tw-text-xs">{{
                    $t('itemAllowance.withholdingTaxPercent')
                }}</label>
                <div class="col-md-12">
                    <div>
                        <InputNumber
                            :max="100"
                            v-model="model.tax_withholding_percent"
                            :formatter="value => `${value}%`"
                            :parser="value => value.replace('%', '')"
                            :class="{
                                'is-invalid': errors.has(
                                    'tax_withholding_percent'
                                )
                            }"
                            :min="0"
                            style="width: 487px"
                        ></InputNumber>
                    </div>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('tax_withholding_percent')"
                    >
                        {{ errors.first('tax_withholding_percent') }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label class="col-label form-label tw-text-xs">{{
                    $t('itemAllowance.paidOnCycle')
                }}</label>
                <div class="col-md-12">
                    <div>
                        <select
                            v-model="model.paid_on_cycle"
                            class="form-control form-select"
                            :class="{
                                'is-invalid': errors.has('paid_on_cycle')
                            }"
                        >
                            <option :value="null">{{ $t('select') }}</option>
                            <option
                                v-for="c in cycleOrders"
                                :key="c.value"
                                :value="c.value"
                            >
                                {{ c.text }}
                            </option>
                        </select>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('paid_on_cycle')"
                        >
                            {{ errors.first('paid_on_cycle') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <Checkbox v-model="model.is_taxable">{{
                    $t('itemAllowance.taxable')
                }}</Checkbox>
            </div>
            <div class="mb-3">
                <Checkbox v-model="model.is_prorate ">{{
                    $t('itemAllowance.prorate')
                }}</Checkbox>
            </div>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
                    <ts-button
                        @click.prevent="() => $emit('cancel')"
                        class="btn-gray"
                    >
                        {{ $t('cancel') }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        outline
                        :waiting="waiting_new"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSaveAddNew"
                    >
                        {{ $t('saveAddNew') }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        :waiting="waiting"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSave"
                    >
                        {{ $t('save') }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        :waiting="waiting"
                    >
                        {{ $t('update') }}</ts-button
                    >
                </div>
            </div>
        </div>
        <Spin fix v-if="loading"></Spin>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { isEmpty } from 'lodash'
import { mapState } from 'vuex'

export default {
    name: 'item-allowance-form',
    data () {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                allowance_item: null,
                is_taxable: false,
                fringe_benefit_percent: null,
                tax_withholding_percent: null,
                paid_on_cycle: null,
                is_prorate:false
            },
            cycleOrders: [
                {
                    value: 1,
                    text: 'First Cycle'
                },
                {
                    value: 2,
                    text: 'Second Cycle'
                }
            ]
        }
    },
    computed: {
        ...mapState('payroll/itemAllowance', ['edit_data']),
        isUpdate () {
            return !isEmpty(this.edit_data)
        }
    },
    methods: {
        async onSave () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('payroll/itemAllowance/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async onSaveAddNew () {
            this.errors = new Errors()
            this.waiting_new = true
            this.$store
                .dispatch('payroll/itemAllowance/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.clearInput()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting_new = false
                })
        },
        onUpdate () {
            this.waiting = true
            this.$store
                .dispatch('payroll/itemAllowance/update', {
                    id: this.edit_data.allowance_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        setEditData () {
            if (!isEmpty(this.edit_data)) {
                this.model.allowance_item = this.edit_data.allowance_item
                this.model.is_taxable = this.edit_data.is_taxable
                this.model.fringe_benefit_percent =
                    this.edit_data.fringe_benefit_percent
                this.model.tax_withholding_percent =
                    this.edit_data.tax_withholding_percent
                this.model.paid_on_cycle = this.edit_data.paid_on_cycle
            }
        },
        clearInput () {
            this.errors = new Errors()
            this.model.allowance_item = null
            this.model.is_taxable = false
            this.model.fringe_benefit_percent = null
            this.model.tax_withholding_percent = null
            this.model.paid_on_cycle = null
            this.model.is_prorate = false
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'ITEM ALLOWANCE',
                desc: not.text
            })
        }
    }
}
</script>
